/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Autoplay, Navigation, Pagination } from 'swiper';

import 'swiper/css/bundle';

import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './styles.module.css';
// Import Swiper styles

export default ({ slideItems, options, ...restProps }) => {
  const { isMobileSeller = false, isBestProducts = false, offNavigation = false } = restProps;

  return (
    <Swiper
      key={options?.autoplay}
      {...options}
      className={styles.mySwiper}
      spaceBetween={10}
      modules={[Autoplay, Pagination, Navigation]}
      slidesPerView="auto"
      navigation={!offNavigation}
      loop
    >
      {slideItems.length > 0 && slideItems.map((SlideItem) => <SwiperSlide key={SlideItem.key}>{SlideItem}</SwiperSlide>)}
    </Swiper>
  );
};
