/* eslint-disable jsx-a11y/anchor-is-valid */
import { IconButton } from '@material-ui/core';
import Container from 'components-v2/atoms/Mobile/Container';
import { HOME_ICON_MEGA_SALE } from 'constants/Images';
import { DAILY_PROMOTION_ICON, LIKE_ICON, NEWPRD_ICON } from 'constants/Images/mobile';
import { SEARCH_ICON } from 'constants/Images/mobile/Icons';
import { NEW_PRODUCTS_URL, PRODUCTS_LOADING_URL, QUICK_ORDER, TAG_PAGE } from 'constants/Paths';
import useTimeout from 'hooks/useTimeout';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { memo, useState } from 'react';
import { TAG_NEW } from 'sysconfig';
import ImageFallback from 'utils/ImageFallback';
import { textSearch } from 'constants/Buydental/data';
import styles from './styles.module.css';

const DynamicComponentPanelMenus = dynamic(() => import('./PanelMenus'), { ssr: false });
const DynamicComponentBannerHome = dynamic(() => import('./BannerHome'), { ssr: false });
const DynamicComponentCampaignBlock = dynamic(() => import('components-v2/mocules/mobile/CampaignBlock'), { ssr: false });
const DynamicComponentGroup = dynamic(() => import('./GroupProductBlock'), { ssr: false });
// const DynamicComponentBannerInsider = dynamic(() => import('./BannerInsider'));
const DynamicSurveyForm = dynamic(() => import('./SurveyForm'), { ssr: false });

const MainContent = ({ provinceCode }) => {
  const [renderBlock1, setRenderBlock1] = useState(false);
  const [renderBlock2, setRenderBlock2] = useState(false);
  const [renderBlock3, setRenderBlock3] = useState(false);

  useTimeout(() => setRenderBlock1(true), 1000);
  // useTimeout(() => setRenderBlock2(true), 1000);
  // useTimeout(() => setRenderBlock3(true), 1500);

  return (
    <div>
      <Container styleContainer={{ paddingTop: '13px' }}>
        <Link
          href={QUICK_ORDER}
          prefetch
          onClick={() => {
            window.location.href = '/quick-order';
          }}
          shallow
        >
          <div className={styles.wrapper}>
            <div className={styles.icon}>
              <ImageFallback src={SEARCH_ICON} width="16px" height="16px" />
            </div>
            <IconButton aria-label="search" disableFocusRipple style={{ padding: 0, width: '90%' }}>
              <input placeholder={textSearch} style={{ width: '100%' }} disabled />
            </IconButton>
          </div>
        </Link>

        <div style={{ margin: '20px 0px' }}>
          <DynamicComponentPanelMenus />
        </div>
        <DynamicComponentBannerHome />
        <div id="insider-minibanners" />
      </Container>
      {/* tạm thời ẩn phổ biến nhất */}
      {/* <MostPopular /> */}
      <div>
        <DynamicComponentGroup
          name="Độc quyền giá tốt"
          type="MEGA-SALE"
          redirectUrl={`${TAG_PAGE}/doc-quyen-gia-tot`}
          provinceCode={provinceCode}
          icon={<ImageFallback src={HOME_ICON_MEGA_SALE} width={24} height={24} />}
        />
      </div>
      {/* {renderBlock1 && (
        <div style={{ marginTop: '10px' }}>
          <DynamicComponentGroup
            icon={<ImageFallback src={LIKE_ICON} width={24} height={24} />}
            name="Bán chạy"
            type="BANCHAY"
            redirectUrl={`${PRODUCTS_LOADING_URL}?currentTab=2`}
          />
        </div>
      )} */}
      {renderBlock2 && (
        <div style={{ marginTop: '10px' }}>
          <DynamicComponentCampaignBlock />
        </div>
      )}
      {/* <Container styleContainer={{ marginTop: '20px' }}>
          <DynamicComponentBannerInsider />
        </Container> */}
      {renderBlock2 && (
        <div style={{ marginTop: '10px' }}>
          <DynamicComponentGroup
            icon={<ImageFallback src={NEWPRD_ICON} width={24} height={24} />}
            name="Sản phẩm mới"
            type={TAG_NEW}
            redirectUrl={NEW_PRODUCTS_URL}
          />
        </div>
      )}
      {renderBlock3 && (
        <div style={{ marginTop: '10px' }}>
          <DynamicComponentGroup
            icon={<ImageFallback src={DAILY_PROMOTION_ICON} width={24} height={24} />}
            name="Khuyến mãi hằng ngày"
            type="DEALS"
            redirectUrl="/deals"
          />
        </div>
      )}

      {renderBlock3 && <DynamicSurveyForm />}
    </div>
  );
};

export default memo(MainContent);
